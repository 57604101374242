<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="row layout-top-spacing">
        <div
          class="col-12 col-md-12 col-lg-8 offset-lg-2 layout-spacing bg-white p-4 shadow"
        >
          <div class="p-2 text-center" v-if="loadingReport">
            <b-card class="">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="67%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="82%"></b-skeleton>
              <b-skeleton animation="wave" width="65%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
              <b-skeleton type="button"></b-skeleton>
            </b-card>
          </div>

          <form v-else>
            <div class="row mb-4">
              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="Name">Name</label>
                  <input
                    type="text"
                    :value="`${report.user.firstname} ${report.user.lastname}`"
                    id="Name"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="dept">Department</label>
                  <input
                    type="text"
                    :value="`${report.user.role.toUpperCase()}`"
                    id="dept"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Routine Tasks</p>
                  <p style="color: #acb0c3">
                    Routine tasks you carry out every week according to your job
                    description. Minimum of 3 and Max of 5. For tech these are
                    tasks you have been instructed to carry out by Management. -
                    50 Points <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in routineTasks"
                    :key="`routineTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="routineTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': routineTasks[index].completed,
                            's-danger': !routineTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            v-model="routineTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Outstanding Tasks</p>
                  <p style="color: #acb0c3">
                    Outstanding Tasks from the previous week - Completion of
                    outstanding tasks from the previous week - 20 Points<span
                      class="text-danger"
                      >*</span
                    >
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in outstandingTasks"
                    :key="`outstandingTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="outstandingTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': outstandingTasks[index].completed,
                            's-danger': !outstandingTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            v-model="outstandingTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">New Tasks</p>
                  <p style="color: #acb0c3">
                    Tasks given to you during the week that may or may not be
                    directly related to your job description - 20 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in newTasks"
                    :key="`newTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="newTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': newTasks[index].completed,
                            's-danger': !newTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            v-model="newTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Any Other Tasks</p>
                  <p style="color: #acb0c3">
                    Pro active tasks you give yourself during the week that move
                    GeroCare forward. - 10 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <div
                    class="input-group mb-4"
                    v-for="(input, index) in otherTasks"
                    :key="`otherTask-${index}`"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      readonly
                      v-model="otherTasks[index].description"
                    ></textarea>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <label
                          class="switch s-primary mb-0"
                          :class="{
                            's-primary': otherTasks[index].completed,
                            's-danger': !otherTasks[index].completed,
                          }"
                        >
                          <input
                            type="checkbox"
                            v-model="otherTasks[index].completed"
                          />
                          <span class="slider round mb-0"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-5" v-if="report.user.role == 'admin'">
                <div>
                  <p class="font-weight">Review of Outstanding Visits</p>
                </div>
                <div class="form-group">
                  <textarea
                    type="text"
                    v-model="report.outstanding_visit_report"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div class="col-12 mb-5" v-if="report.user.role == 'accounts'">
                <div>
                  <p class="font-weight">Payment Log Report</p>
                </div>
                <div class="form-group">
                  <textarea
                    type="text"
                    v-model="report.payment_log"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div
                class="col-12 mb-5"
                v-if="report.user.role == 'medical services'"
              >
                <div>
                  <p class="font-weight">
                    Update on Corporate Visits/First Visits
                  </p>
                </div>
                <div class="form-group">
                  <textarea
                    type="text"
                    v-model="report.corporate_client_update"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div
                class="col-12 mb-5"
                v-if="report.user.role == 'elderly services'"
              >
                <div>
                  <p class="font-weight">
                    Elderly Remarks for Visits in the Previous Week
                  </p>
                </div>
                <div class="form-group">
                  <textarea
                    type="text"
                    v-model="report.elderly_remark"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div
                class="col-12 mb-5"
                v-if="report.user.role == 'client services'"
              >
                <div>
                  <p class="font-weight">Outstanding Payments/Weekly Update</p>
                </div>
                <div class="form-group">
                  <textarea
                    type="text"
                    v-model="report.general_report"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div class="col-12 text-center">
                <button
                  type="button"
                  @click.prevent="gradeTask()"
                  class="btn btn-success"
                  :disabled="loading"
                >
                  <b-spinner small v-if="loading"></b-spinner>
                  <span v-else
                    >Save Changes<i class="fa fa-paper-plane"></i>
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="fab-container score" id="scoreBox" :key="score">
      <div class="fab shadow">
        <div class="fab-content">
          <b-spinner
            v-if="loadingReport"
            variant="light"
            type="grow"
            label="Spinning"
          ></b-spinner>
          <span v-else> {{ score }} pts</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "GradeTask",

  computed: {
    ...mapGetters(["user"]),

    score: function () {
      return (
        Math.round(this.routineScore) +
        Math.round(this.newScore) +
        Math.round(this.otherScore) +
        Math.round(this.outstandingScore)
      );
    },
  },

  watch: {
    routineTasks: {
      handler(val) {
        if (this.routineTasks.length > 0) {
          var total = this.routineTasks.length;
          var complete = this.routineTasks.filter((x) => x.completed).length;
          var percentage = (complete / total) * 100;
          this.routineScore = (50 / 100) * percentage;
        } else {
          this.routineScore = 0;
        }
      },
      deep: true,
    },
    newTasks: {
      handler(val) {
        if (this.newTasks.length > 0) {
          var total = this.newTasks.length;
          var complete = this.newTasks.filter((x) => x.completed).length;
          var percentage = (complete / total) * 100;
          this.newScore = (20 / 100) * percentage;
        } else {
          this.newScore = 0;
        }
      },
      deep: true,
    },
    otherTasks: {
      handler(val) {
        if (this.otherTasks.length > 0) {
          var total = this.otherTasks.length;
          var complete = this.otherTasks.filter((x) => x.completed).length;
          var percentage = (complete / total) * 100;
          this.otherScore = (10 / 100) * percentage;
        } else {
          this.otherScore = 0;
        }
      },
      deep: true,
    },
    outstandingTasks: {
      handler(val) {
        if (this.outstandingTasks.length > 0) {
          var total = this.outstandingTasks.length;
          var complete = this.outstandingTasks.filter(
            (x) => x.completed
          ).length;
          var percentage = (complete / total) * 100;
          this.outstandingScore = (20 / 100) * percentage;
        } else {
          this.outstandingScore = 20;
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      loadingReport: true,
      loading: false,

      routineTasks: [],
      routineScore: 0,

      newTasks: [],
      newScore: 0,

      otherTasks: [],
      otherScore: 0,

      outstandingTasks: [],
      outstandingScore: 0,

      date: null,
      prevScore: null,

      id: null,

      report: [],

      deptReportTitle: null,
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    if (this.id == null) {
      this.fetchLastReport();
    } else {
      this.fetchReport();
    }
  },

  methods: {
    fetchLastReport() {
      this.loadingReport = true;
      http
        .get(endpoints.USER_LAST_TASK)
        .then((response) => {
          //if user has no previous tasks then go ahead to the create report page
          if (_.isEmpty(response)) {
            this.$router.push("/tasks/create");
          } else {
            this.report = response;

            this.routineTasks = this.report.items.filter((x) => {
              return x.type == "routine";
            });

            this.newTasks = this.report.items.filter((x) => {
              return x.type == "new";
            });

            this.otherTasks = this.report.items.filter((x) => {
              return x.type == "other";
            });

            this.outstandingTasks = this.report.items.filter((x) => {
              return x.type == "outstanding";
            });

            this.loadingReport = false;
            this.checkDeptReportType();
          }
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    fetchReport() {
      this.loadingReport = true;
      http
        .get(endpoints.SINGLE_TASK.replace(":id", this.id))
        .then((response) => {
          this.report = response;

          this.routineTasks = this.report.items.filter((x) => {
            return x.type == "routine";
          });

          this.newTasks = this.report.items.filter((x) => {
            return x.type == "new";
          });

          this.otherTasks = this.report.items.filter((x) => {
            return x.type == "other";
          });

          this.outstandingTasks = this.report.items.filter((x) => {
            return x.type == "outstanding";
          });

          this.loadingReport = false;
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    gradeTask() {
      this.loading = true;
      http
        .put(endpoints.GRADE_TASK, {
          ...this.report,
          id: this.id ?? this.report.id,
          routineTasks: this.routineTasks,
          newTasks: this.newTasks,
          otherTasks: this.otherTasks,
          outstandingTasks: this.outstandingTasks,
          score: this.score,
        })
        .then((response) => {
          this.loading = false;
          this.$toast.success(response);

          if (this.id == null) {
            this.$router.push("/tasks/create");
          } else {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    checkDeptReportType() {
      switch (this.report.user.role) {
        case "admin":
          this.deptReportTitle = "Review of Outstanding Visits";
          break;
        case "accounts":
          this.deptReportTitle = "Payment Log Report";
          break;
        case "medical services":
          this.deptReportTitle = "Update on Corporate Visits/First Visits";
          break;
        case "client services":
          this.deptReportTitle = "Outstanding Payments/Weekly Update";
          break;
        case "elderly services":
          this.deptReportTitle =
            "Elderly Remarks for Visits in the Previous Week";
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/forms/theme-checkbox-radio.css"></style>
<style scoped src="@/assets/css/forms/switches.css"></style>

<style>
.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: sticky;
  bottom: 30px;
  right: 30px;
}

.fab-container .fab {
  position: relative;
  height: 90px;
  width: 90px;
  background-color: #28a745;
  border-radius: 50%;
  z-index: 9999;
}
.fab-container .fab::before {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: inherit;
  border-radius: 0 0 10px 0;
  z-index: -1;
}
.fab-container .fab .fab-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  width: 100%;
  border-radius: 50%;
}

.score {
  animation: bounce 1s;
}
@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
</style>
